@font-face {
  font-family: "Montserrat";
  src: url("/content/commun/fonts/montserrat-regular-webfont.eot");
  src: url("/content/commun/fonts/montserrat-regular-webfont.eot?#iefix") format("embedded-opentype"), url("/content/commun/fonts/montserrat-regular-webfont.woff2") format("woff2"), url("/content/commun/fonts/montserrat-regular-webfont.woff") format("woff"), url("/content/commun/fonts/montserrat-regular-webfont.ttf") format("truetype"), url("/content/commun/fonts/montserrat-regular-webfont.svg#montserratregular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "Montserrat";
  src: url("/content/commun/fonts/montserrat-thin-webfont.eot");
  src: url("/content/commun/fonts/montserrat-thin-webfont.eot?#iefix") format("embedded-opentype"), url("/content/commun/fonts/montserrat-thin-webfont.woff2") format("woff2"), url("/content/commun/fonts/montserrat-thin-webfont.woff") format("woff"), url("/content/commun/fonts/montserrat-thin-webfont.ttf") format("truetype"), url("/content/commun/fonts/montserrat-thin-webfont.svg#montserratthin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "Montserrat";
  src: url("/content/commun/fonts/montserrat-extralight-webfont.woff2") format("woff2"), url("/content/commun/fonts/montserrat-extralight-webfont.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "Montserrat";
  src: url("/content/commun/fonts/montserrat-light-webfont.eot");
  src: url("/content/commun/fonts/montserrat-light-webfont.eot?#iefix") format("embedded-opentype"), url("/content/commun/fonts/montserrat-light-webfont.woff2") format("woff2"), url("/content/commun/fonts/montserrat-light-webfont.woff") format("woff"), url("/content/commun/fonts/montserrat-light-webfont.ttf") format("truetype"), url("/content/commun/fonts/montserrat-light-webfont.svg#montserratlight") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "Montserrat";
  src: url("/content/commun/fonts/montserrat-regular-webfont.eot");
  src: url("/content/commun/fonts/montserrat-regular-webfont.eot?#iefix") format("embedded-opentype"), url("/content/commun/fonts/montserrat-regular-webfont.woff2") format("woff2"), url("/content/commun/fonts/montserrat-regular-webfont.woff") format("woff"), url("/content/commun/fonts/montserrat-regular-webfont.ttf") format("truetype"), url("/content/commun/fonts/montserrat-regular-webfont.svg#montserratregular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "Montserrat";
  src: url("/content/commun/fonts/montserrat-medium-webfont.woff2") format("woff2"), url("/content/commun/fonts/montserrat-medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "Montserrat";
  src: url("/content/commun/fonts/montserrat-bold-webfont.eot");
  src: url("/content/commun/fonts/montserrat-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/content/commun/fonts/montserrat-bold-webfont.woff2") format("woff2"), url("/content/commun/fonts/montserrat-bold-webfont.woff") format("woff"), url("/content/commun/fonts/montserrat-bold-webfont.ttf") format("truetype"), url("/content/commun/fonts/montserrat-bold-webfont.svg#montserratbold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "Montserrat";
  src: url("/content/commun/fonts/montserrat-extrabold-webfont.woff2") format("woff2"), url("/content/commun/fonts/montserrat-extrabold-webfont.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "Montserrat";
  src: url("/content/commun/fonts/montserrat-black-webfont.eot");
  src: url("/content/commun/fonts/montserrat-black-webfont.eot?#iefix") format("embedded-opentype"), url("/content/commun/fonts/montserrat-black-webfont.woff2") format("woff2"), url("/content/commun/fonts/montserrat-black-webfont.woff") format("woff"), url("/content/commun/fonts/montserrat-black-webfont.ttf") format("truetype"), url("/content/commun/fonts/montserrat-black-webfont.svg#montserratblack") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "Open Sans";
  src: url("/content/commun/fonts/opensans-light-webfont.eot");
  src: url("/content/commun/fonts/opensans-light-webfont.eot?#iefix") format("embedded-opentype"), url("/content/commun/fonts/opensans-light-webfont.woff2") format("woff2"), url("/content/commun/fonts/opensans-light-webfont.woff") format("woff"), url("/content/commun/fonts/opensans-light-webfont.ttf") format("truetype"), url("/content/commun/fonts/opensans-light-webfont.svg#open_sanslight") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "Open Sans";
  src: url("/content/commun/fonts/opensans-lightitalic-webfont.woff2") format("woff2"), url("/content/commun/fonts/opensans-lightitalic-webfont.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "Open Sans";
  src: url("/content/commun/fonts/opensans-regular-webfont.eot");
  src: url("/content/commun/fonts/opensans-regular-webfont.eot?#iefix") format("embedded-opentype"), url("/content/commun/fonts/opensans-regular-webfont.woff2") format("woff2"), url("/content/commun/fonts/opensans-regular-webfont.woff") format("woff"), url("/content/commun/fonts/opensans-regular-webfont.ttf") format("truetype"), url("/content/commun/fonts/opensans-regular-webfont.svg#open_sansregular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "Open Sans";
  src: url("/content/commun/fonts/opensans-italic-webfont.eot");
  src: url("/content/commun/fonts/opensans-italic-webfont.eot?#iefix") format("embedded-opentype"), url("/content/commun/fonts/opensans-italic-webfont.woff2") format("woff2"), url("/content/commun/fonts/opensans-italic-webfont.woff") format("woff"), url("/content/commun/fonts/opensans-italic-webfont.ttf") format("truetype"), url("/content/commun/fonts/opensans-italic-webfont.svg#open_sansitalic") format("svg");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "Open Sans";
  src: url("/content/commun/fonts/opensans-semibold-webfont.eot");
  src: url("/content/commun/fonts/opensans-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("/content/commun/fonts/opensans-semibold-webfont.woff2") format("woff2"), url("/content/commun/fonts/opensans-semibold-webfont.woff") format("woff"), url("/content/commun/fonts/opensans-semibold-webfont.ttf") format("truetype"), url("/content/commun/fonts/opensans-semibold-webfont.svg#open_sanssemibold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "Open Sans Semi Bold";
  src: url("/content/commun/fonts/opensans-semibold-webfont.eot");
  src: url("/content/commun/fonts/opensans-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("/content/commun/fonts/opensans-semibold-webfont.woff2") format("woff2"), url("/content/commun/fonts/opensans-semibold-webfont.woff") format("woff"), url("/content/commun/fonts/opensans-semibold-webfont.ttf") format("truetype"), url("/content/commun/fonts/opensans-semibold-webfont.svg#open_sanssemibold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "Open Sans";
  src: url("/content/commun/fonts/opensans-semibolditalic-webfont.woff2") format("woff2"), url("/content/commun/fonts/opensans-semibolditalic-webfont.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "Open Sans";
  src: url("/content/commun/fonts/opensans-bold-webfont.eot");
  src: url("/content/commun/fonts/opensans-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/content/commun/fonts/opensans-bold-webfont.woff2") format("woff2"), url("/content/commun/fonts/opensans-bold-webfont.woff") format("woff"), url("/content/commun/fonts/opensans-bold-webfont.ttf") format("truetype"), url("/..fonts/opensans-bold-webfont.svg#open_sansbold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "Open Sans";
  src: url("/content/commun/fonts/opensans-bolditalic-webfont.woff2") format("woff2"), url("/content/commun/fonts/opensans-bolditalic-webfont.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "Open Sans";
  src: url("/content/commun/fonts/opensans-extrabold-webfont.woff2") format("woff2"), url("/content/commun/fonts/opensans-extrabold-webfont.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "Open Sans";
  src: url("/content/commun/fonts/opensans-extrabolditalic-webfont.woff2") format("woff2"), url("/content/commun/fonts/opensans-extrabolditalic-webfont.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}